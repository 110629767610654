import React from 'react';
import { FormControl, FormField, FormItem, FormMessage } from '@av/ui/form';
import Counter from '@/components/form/counter/counter';
import { useFormContext } from 'react-hook-form';

const ProductQuantityForm = ({
  loading,
  minQuantity,
  setQuantity,
  step,
}: {
  loading: boolean;
  minQuantity?: number | null;
  setQuantity?: number | null;
  step?: number | null;
}) => {
  const { control } = useFormContext<{ quantity: number }>();

  return (
    <FormField
      control={control}
      name="quantity"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <div className="relative">
              <Counter
                disabled={loading}
                step={step || 1}
                min={minQuantity || 1}
                value={field.value}
                onChange={field.onChange}
                setQuantity={setQuantity || undefined}
                data-testid="quantity-input"
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ProductQuantityForm;
