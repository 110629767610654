'use client';

import * as React from 'react';
import Image from 'next/image';
import { cn, MediaValue, AlignedTextValue } from '@av/utils';
import { Inner } from '../lib/setHtml';

const checkIfNumberExists = (value: string) => {
  return value && !isNaN(parseInt(value)) && !isNaN(parseFloat(value));
};

const ImageBlock = ({
  image,
  alt,
  width,
  height,
  maxWidth,
  radius,
  align,
  alignedText,
}: {
  image: MediaValue;
  alignedText: AlignedTextValue;
  alt: string;
  width: string;
  height: string;
  maxWidth: string;
  radius: string;
  align: 'left' | 'right' | 'center';
}) => {
  if (!image?.url) return null;

  const imageMaxWidth = ['left', 'right'].includes(alignedText.align)
    ? '50%'
    : checkIfNumberExists(maxWidth)
      ? maxWidth
      : '100%';

  return (
    <div
      className={cn(
        'flex max-md:flex-col-reverse w-full',
        alignedText.align !== 'none' && 'gap-4',
        alignedText.align === 'left' && 'flex-row-reverse'
      )}
    >
      <Image
        src={image.url}
        height={parseInt(height) || image.height || 0}
        width={parseInt(width) || image.width || 0}
        alt={image.alt || alt || ''}
        className={cn(
          'object-cover object-top max-md:!max-w-full',
          align === 'right' && 'ml-auto',
          align === 'center' && 'mx-auto'
        )}
        style={{
          borderRadius: checkIfNumberExists(radius) ? radius : undefined,
          height: checkIfNumberExists(height) ? height : undefined,
          width: checkIfNumberExists(width) ? width : 'auto',
          maxWidth: imageMaxWidth,
        }}
      />
      {alignedText.align !== 'none' && (
        <Inner
          className={cn(
            'flex-1',
            align === 'center' && 'text-center',
            align === 'left' && 'text-left',
            align === 'right' && 'text-right'
          )}
          html={alignedText.text}
        />
      )}
    </div>
  );
};

export default ImageBlock;
