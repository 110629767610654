import React from 'react';
import { blocks } from '../lib/blocks';
import { Section } from '@/components/section/section';
import { IframeSectionsEventHandler } from './iframe-sections-event-handler';
import { BlockType } from '@av/utils';

const BlocksRenderer = ({
  blocks: blocksData,
  client,
}: {
  blocks: BlockType[];
  client?: boolean;
}) => {
  return (
    <>
      {!!client && <IframeSectionsEventHandler length={blocksData.length} />}
      {blocksData.map(({ name, fields, id, uid, padding, ...props }: BlockType) => {
        const Component = blocks[name];

        return (
          <Section key={uid} {...props} padding={padding as 'none'} id={uid} innerId={id}>
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <Component {...(fields as any)} />
          </Section>
        );
      })}
    </>
  );
};

export default BlocksRenderer;
