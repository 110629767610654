import { cn } from '@av/utils';
import React, { ImgHTMLAttributes } from 'react';

type CTAProps = React.HTMLAttributes<HTMLDivElement>;

const CTA: React.FC<CTAProps> = ({ children, className, ...props }) => {
  return (
    <div className={cn('w-full', className)} {...props}>
      {children}
    </div>
  );
};
CTA.displayName = 'CTA';

const CTAContent = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div
          ref={ref}
          className={cn(
            'flex flex-col space-y-3 rounded-xl px-4 py-14 shadow-sm sm:px-6 lg:px-8 ',
            className
          )}
          {...props}
        >
          {children}
        </div>
      </div>
    );
  }
);
CTAContent.displayName = 'CTAContent';

const CTASection = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('mx-auto max-w-2xl text-center xl:mx-0 xl:text-left', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);
CTASection.displayName = 'CTASection';

const CTATitle = React.forwardRef<HTMLHeadingElement, React.ComponentPropsWithoutRef<'h2'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <h2
        ref={ref}
        className={cn(
          'text-3xl font-semibold leading-tight tracking-wide md:text-4xl xl:text-5xl',
          className
        )}
        {...props}
      >
        {children}
      </h2>
    );
  }
);
CTATitle.displayName = 'CTATitle';

const CTASubtitle = React.forwardRef<HTMLParagraphElement, React.ComponentPropsWithoutRef<'p'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <p ref={ref} className={cn('max-w-2xl text-base text md:text-lg', className)} {...props}>
        {children}
      </p>
    );
  }
);
CTASubtitle.displayName = 'CTASubtitle';

const CTAActions = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4 max-w-md',
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);
CTAActions.displayName = 'CTAActions';

const CTABackdrop: React.FC<ImgHTMLAttributes<HTMLImageElement>> = ({ className, ...props }) => {
  return (
    <img
      className={cn('absolute inset-0 -z-50 h-full w-full object-cover brightness-100', className)}
      {...props}
    />
  );
};

export { CTA, CTAActions, CTABackdrop, CTAContent, CTASection, CTASubtitle, CTATitle };
