import React from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@av/ui/accordion';
import { Card, CardHeader } from '@av/ui/card';
import RichContent from './rich-content';

const AccordionBlock = ({
  items,
  title,
}: {
  title: string;
  items: { title: string; body: string }[];
}) => {
  return (
    <Card className="overflow-hidden">
      {!!title && <CardHeader className="text-xl text-primary md:text-2xl">{title}</CardHeader>}
      <Accordion type="single" collapsible className="w-full">
        {items?.map(({ title, body }, i) => (
          <AccordionItem value={i.toString()}>
            <AccordionTrigger className="px-6">{title}</AccordionTrigger>
            <AccordionContent className="p-6 pt-0">
              <RichContent content={body} />
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </Card>
  );
};

export default AccordionBlock;
