'use client';

import { useEffect, useState } from 'react';

export function IframeSectionsEventHandler({ length }: { length: number }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    const handleEvent = () => {
      if (mounted)
        parent.postMessage({ height: document.body.clientHeight, action: 'RESIZE' }, '*');
    };

    handleEvent();

    window.addEventListener('resize', handleEvent);

    return () => {
      window.removeEventListener('resize', handleEvent);
    };
  }, [mounted, length]);

  return <></>;
}
