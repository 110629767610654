import React from 'react';
import {
  CTA,
  CTAActions,
  CTABackdrop,
  CTAContent,
  CTASubtitle,
  CTATitle,
} from '@/components/cta/cta';
import { Button, ButtonProps } from '@av/ui/button';
import { setHtml } from '../lib/setHtml';
import { MediaValue } from '@av/utils';

const RichContent = ({
  subtitle,
  title,
  description,
  buttons,
  media = { url: '/images/hero-home.webp', height: 650, width: 1440, alt: 'cta' },
}: {
  subtitle: string;
  title: string;
  description: string;
  buttons: ButtonProps[];
  media: MediaValue | null;
}) => {
  return (
    <CTA>
      {!!media && (
        <CTABackdrop
          alt={media.alt}
          height={media.height}
          className=""
          src={media.url}
          width={media.width}
        />
      )}
      <div className="py-32">
        <CTAContent className="max-w-3xl rounded-none bg-white shadow-lg lg:px-16 lg:py-12">
          {!!subtitle && <CTASubtitle className="text-primary">{subtitle}</CTASubtitle>}
          {!!title && <CTATitle className="text-foreground">{title}</CTATitle>}
          {!!description && <div className="py-2 text-description" {...setHtml(description)} />}
          {!!buttons?.length && (
            <CTAActions>
              {buttons.map(({ href, withArrow, variant, ...props }, i) => (
                <Button
                  key={i}
                  href={href}
                  withArrow={withArrow}
                  variant={variant}
                  className="rounded-none"
                  {...props}
                />
              ))}
            </CTAActions>
          )}
        </CTAContent>
      </div>
    </CTA>
  );
};

export default RichContent;
