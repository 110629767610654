import type { CSSProperties } from 'react';
import RichContent from '../blocks/rich-content';
import CTA from '../blocks/cta';
import { BlockNames } from '@av/utils';
import Features from '../blocks/features';
import InfoBlocks from '../blocks/info-blocks';
import Products from '../blocks/products';
import TransportCostCalculator from '../blocks/transport-cost-calculator';
import AccordionBlock from '../blocks/accordion';
import ImageBlock from '../blocks/image';
import ColumnsBlock from '../blocks/columns';

export interface Block {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: any;
  name: 'richContent' | 'cta' | 'columns';
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  id: string;
  uid: string;
  label: string;
}

export const blocks = {
  [BlockNames.richContent]: RichContent,
  [BlockNames.cta]: CTA,
  [BlockNames.features]: Features,
  [BlockNames.infoBlocks]: InfoBlocks,
  [BlockNames.products]: Products,
  [BlockNames.transportCostCalculator]: TransportCostCalculator,
  [BlockNames.accordion]: AccordionBlock,
  [BlockNames.image]: ImageBlock,
  [BlockNames.columns]: ColumnsBlock,
};
