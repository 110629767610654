'use client';

import React from 'react';

import BlocksRenderer from './blocks-renderer';
import IframeMessageProvider from './iframe-message-provider';
import { BlockType } from '@av/utils';

const Client = ({ blocks }: { blocks: BlockType[] }) => {
  return (
    <IframeMessageProvider<BlockType[]> defaultValue={blocks} eventType="SET_BLOCKS">
      {(data: BlockType[]) => {
        return <BlocksRenderer blocks={data} client />;
      }}
    </IframeMessageProvider>
  );
};

export default Client;
