import { cn } from '@av/utils';
import React, { HTMLAttributes } from 'react';

const BlockHeading = ({
  children,
  underline,
  as: Component = 'h2',
  ...props
}: HTMLAttributes<HTMLHeadingElement> & {
  underline?: boolean;
  as?: 'h1' | 'h2';
}) => {
  return (
    <Component
      className={cn(
        'font-bold text-3xl md:text-4xl',
        underline
          ? 'relative pb-5 after:absolute after:h-[3px] after:bottom-0 after:left-0 after:w-[100px] after:bg-primary'
          : ''
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export default BlockHeading;
