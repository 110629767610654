'use client';

import React, { useEffect, useState } from 'react';
import BlockHeading from '../components/block-heading';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@av/ui/carousel';
import ProductCard from '@/app/assortiment/[[...slug]]/components/product-card';
import { getObjects, Indices, SearchProduct } from '@av/algolia';
import { Product } from '@av/database';

const Products = ({ title, productIds }: { title: string; productIds: number[] }) => {
  const [products, setProducts] = useState<SearchProduct[]>([]);

  useEffect(() => {
    getObjects(
      productIds.map((id) => id.toString()),
      Indices.PRODUCTS
    ).then(({ results }) => setProducts(results.filter((p) => p?.in_shop)));
  }, [productIds]);

  return (
    <div className="space-y-5 max-md:relative max-md:pb-20 md:space-y-10">
      <BlockHeading underline>{title}</BlockHeading>
      {!!products?.length && (
        <Carousel className="min-h-[20.5rem] w-full" opts={{ loop: true, align: 'start' }}>
          <CarouselContent>
            {products?.map((product, index) => (
              <CarouselItem key={index} className="md:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                <ProductCard
                  product={
                    {
                      ...product,
                      thumbnailImage: product.shop_meta?.image_url || '',
                      images: product.shop_meta?.images,
                      unitPrice: product.specifications?.price || 0,
                      taxRate: product.specifications?.tax_rate || 0,
                    } as unknown as Product
                  }
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="max-md:-bottom-24 max-md:left-0 max-md:top-auto md:-top-[4.75rem] md:left-auto md:right-16" />
          <CarouselNext className="max-md:-bottom-24 max-md:right-0 max-md:top-auto md:-top-[4.75rem] md:left-auto md:right-0" />
        </Carousel>
      )}
    </div>
  );
};

export default Products;
