'use client';

import React, { useState, useTransition } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { Button } from '@av/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@av/ui/form';
import { api } from '@/trpc/react';
import { TransportCostCalculatorValues, transportCostSchema } from '@av/validators';
import { FloatingLabelInput as Input } from '@/components/form/counter/floating-label-input';
import { Card, CardContent } from '@av/ui/card';
import { formatter, TAX_HIGH_FACTOR } from '@av/utils';
import { notifyError } from '@av/bugsnag';
import { useTaxState } from '@/app/components/context/tax-state-context';

const TransportCostCalculator = ({ label }: { label: string }) => {
  const [loading, startTransition] = useTransition();
  const [cost, setCost] = useState<number | null>(null);
  const costByPostalCode = api.transportCost.checkCostByPostalCode.useMutation();
  const { isTaxIncluded } = useTaxState();

  const form = useForm<TransportCostCalculatorValues>({
    resolver: zodResolver(transportCostSchema),
  });

  const onSubmit = async (data: TransportCostCalculatorValues) => {
    startTransition(async () => {
      try {
        const result = await costByPostalCode.mutateAsync(data);
        if (result?.cost) setCost(result.cost);
      } catch (e) {
        notifyError(e);
      }
    });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-4">
        <FormField
          control={form.control}
          name="postalCode"
          render={({ field }) => (
            <FormItem className="max-w-md">
              <FormControl>
                <Input disabled={loading} label={label} {...field} value={field.value || ''} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={loading}>Bereken kosten</Button>
        {cost !== null && cost >= 0 && (
          <Card className="w-fit bg-green-100">
            <CardContent className="">
              {`De kosten voor een bezorging naar dit adres bedragen: ${formatter.format(cost / (isTaxIncluded ? 1 : 1 + TAX_HIGH_FACTOR) / 100)} (${isTaxIncluded ? 'Incl. BTW' : 'Excl. BTW'})`}
            </CardContent>
          </Card>
        )}
      </form>
    </Form>
  );
};

export default TransportCostCalculator;
